import * as React from "react"
import SettingsMenu from "../../components/SettingMenu"
import SideBarLayout from "../../components/SideBarLayout"
import { Link } from "gatsby"

const SettingsPage = () => {
  // React.useEffect(() => { })

  return (
    <>
      <SideBarLayout>
        <aside className="mt-16  w-full h-auto md:w-1/4 md:h-screen md:mr-8 border shadow md:hidden pb-8">
          <div className="">
            <h2 className="pl-8 mt-16 text-sc-titles"><b>Configuración</b></h2>
            <ul>
              <li className="pl-4 pr-8">
                <Link to="/settings/status" className="text-sc-titles" style={{ fontSize: 16 }}>
                  <div className="p-4">
                    <b>Estatus de la cuenta</b>
                  </div>
                  <hr className="mt-4"></hr>
                </Link>
              </li>

              <li className="pl-4 pr-8">
                <Link to="/settings/security" className="text-sc-titles" style={{ fontSize: 16 }}>
                  <div className="p-4">
                    <b>Conectividad</b>
                  </div>
                  <hr className="mt-4"></hr>
                </Link>
              </li>
              
            </ul>
          </div>
        </aside>
        <SettingsMenu className="hidden md:block">
          <div className="hidden md:block">
            <h1>Configuración</h1>
            <p></p>
          </div>
        </SettingsMenu>
      </SideBarLayout>
    </>
  )
}

export default SettingsPage
